declare global {
  interface Window {
    zE: (
      command: string,
      ...args: any[]
    ) => void;
  }
}

/**
 * @description Show the Zendesk widget
 *
 * @param options Object with the following properties:
 * - minWidth: number - The minimum width of the window to show the Zendesk widget (default is 0)
 * - timeout: number - The time in milliseconds to wait before showing the Zendesk widget (default is 0)
 */
export const handleZendeskShow = (options?: {
  minWidth?: number;
  timeout: number;
}) => {
  const defaults = { minWidth: 0, timeout: 0 };
  const { minWidth, timeout } = { ...defaults, ...options };

  if (typeof window.zE === 'function') {
    if (window.innerWidth > minWidth) {
      setTimeout(() => {
        try {
          window.zE('messenger', 'open');
        } catch (error) {
          console.error('Error opening Zendesk Messaging Web Widget:', error);
        }
      }, timeout);
    }
  } else {
    console.warn('Failed to show Zendesk, zE is not defined on the window object.');
  }
};

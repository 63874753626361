import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { ApiCalls, EventTypes, useLogEvent } from '@/app/hooks/useLogEvent';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import persistenceService from '../../lib/utils/persistenceService';
import { STORAGE_KEY } from '../contexts/global/actions';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { setBrazeKeyValuePair } from '../[lang]/[partner]/braze-sdk';

export const flattenKeyValuePairs = (obj: any, parentKey = '', result = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = parentKey ? `${parentKey}_${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flattenKeyValuePairs(obj[key], newKey, result);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item: any, index: number) => {
          flattenKeyValuePairs({ [index]: item }, newKey, result);
        });
      } else {
        (result as any)[newKey] = obj[key];
      }
    }
  }
  return result;
}

const useUpdateLead = (partner: string) => {
  const { dispatch } = useGlobalStore();
  const logApiCall = useLogEvent(EventTypes.API_CALL);

  return async (options?: { cancelLead?: boolean, data?: any }) => {
    const storedData = persistenceService.get(STORAGE_KEY);
    const data = {...storedData, ...options?.data};

    const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
    const partnerURLHost = host_url?.replace('{partner}', partner);

    const traceId = generateUniqueId();
    const spanId = generateUniqueId();

    if (data?.sessionId) {
      logApiCall(ApiCalls.UPDATE_LEAD, traceId, spanId);
    } else if (!options?.cancelLead) {
      logApiCall(ApiCalls.CREATE_LEAD, traceId, spanId);
    }

    if (options?.cancelLead) {
      data.leadProgressedToOffer = true;
      logApiCall(ApiCalls.CLOSE_LEAD, traceId, spanId);
    }

    if (data?.offersPayload?.consents?.bureau_search) {
      data.offersPayload.consents.bureau_search = false;
    }

    if (data?.acceptPayloadBody) {
      delete data.acceptPayloadBody;
    }

    try {
      try {
        await Promise.all([
          setBrazeKeyValuePair('Partner', partner),
          setBrazeKeyValuePair('email', data.offersPayload?.applicants?.[0]?.email_address),
          setBrazeKeyValuePair('email_subscribe', data.offersPayload?.consents?.application_comms ? 'subscribed' : 'unsubscribed')
        ]);
      } catch (error) {
        console.log('Error setting Braze key-value pairs:', error);
      }
      const response = await fetch(`${partnerURLHost}/api/${partner}/lead`, {
        method: 'POST',
        headers: {
          'X-Datadog-Trace-Id': traceId,
          'X-Datadog-Parent-Id': spanId,
          'X-Datadog-Origin': 'local',
          'X-Datadog-Sampling-Priority': '1',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        dispatch(actions.addSessionId(jsonResponse));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export { useUpdateLead };

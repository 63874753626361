import React, { ComponentType, ButtonHTMLAttributes } from 'react';
import cx from 'classnames';

export interface TypographyProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'variant' | 'color'> {
  component?: ComponentType;
  weight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black';
  size?:
    | 'xs'
    | 'sm'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  variant?: keyof typeof defaultVariantMapping;
  variantMapping?: typeof defaultVariantMapping;
  leading?: string;
}

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  inherit: 'p',
};

export const Typography = (props: TypographyProps) => {
  const {
    component,
    variant = 'body1',
    size = 'base',
    weight = 'normal',
    variantMapping = defaultVariantMapping,
    className,
    leading = 'leading-7',
    ...other
  } = props;

  const Component: any =
    component ||
    variantMapping[variant] ||
    defaultVariantMapping[variant] ||
    'span';
  return (
    <Component
      className={cx(
        className,
        {
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-lg': size === 'lg',
          'text-xl': size === 'xl',
          'text-2xl': size === '2xl',
          'text-3xl': size === '3xl',
          'text-4xl': size === '4xl',
          'text-5xl': size === '5xl',
          'text-6xl': size === '6xl',
          'text-7xl': size === '7xl',
          'text-8xl': size === '8xl',
          'text-9xl': size === '9xl',

          'font-thin': weight === 'thin',
          'font-extralight': weight === 'extralight',
          'font-light': weight === 'light',
          'font-normal': weight === 'normal',
          'font-medium': weight === 'medium',
          'font-semibold': weight === 'semibold',
          'font-bold': weight === 'bold',
          'font-extrabold': weight === 'extrabold',
          'font-black': weight === 'black',
        },
        leading
      )}
      {...other}
    />
  );
};

import { isTag, isText } from 'domhandler';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import Link from 'next/link';
import { useMemo } from 'react';
import { Typography, TypographyProps } from '../Typography';

export default interface ParsedTextProps extends TypographyProps {
  htmlString: string;
  maskElements?: boolean;
}

export const ParsedText = ({
  htmlString,
  maskElements,
  ...rest
}: ParsedTextProps) => {
  const parsedContent = useMemo(() => {
    const options = {
      replace: (domNode: any) => {
        if (!isTag(domNode)) return null;
        switch (domNode.name) {
          // anchor links
          case 'a': {
            const { href, target, className } = attributesToProps(
              domNode.attribs,
            );

            const child = domNode.children[0];
            return (
              <Link
                key={href}
                href={href}
                target={target}
                className={className}
              >
                {isText(child) && child.data}
              </Link>
            );
          }
          // strong bold text
          case 'strong': {
            const { href } = domNode.attribs;
            const child = domNode.children[0];
            return maskElements ? (
              <strong data-dd-privacy="mask" key={href}>
                {isText(child) && child.data}
              </strong>
            ) : (
              <strong key={href}>{isText(child) && child.data}</strong>
            );
          }
          // breakpoint
          case 'br': {
            return <br />;
          }
          // para / paragraph;
          case 'p': {
            return maskElements ? (
              <p data-dd-privacy="mask">
                {domToReact(domNode.children, options)}
              </p>
            ) : (
              <p>{domToReact(domNode.children, options)}</p>
            );
          }
          case 'text': {
            const child = domNode.children[0];
            const { size = undefined } = domNode.attribs;

            return (
              <Typography size={size as never}>
                {isText(child) && child.data}
              </Typography>
            );
          }
          // span
          case 'span': {
            const { className, classname } = domNode.attribs;

            return maskElements ? (
              <span
                data-dd-privacy="mask"
                {...{ className: className || classname }}
              >
                {domToReact(domNode.children, options)}
              </span>
            ) : (
              <span {...{ className: className || classname }}>
                {domToReact(domNode.children, options)}
              </span>
            );
          }
          // removes any other tag
          default:
            return <>{domToReact(domNode.children, options)}</>;
        }
      },
    };

    return parse(htmlString, options);
  }, [htmlString]);

  return parsedContent;
};
